import is from './is';

const safeGet = (o, ...path) => {
  if (!is.defined(o) || !is.defined(path) || !is.array(path)) {
    return undefined;
  }

  path.find((node) => {
    o = o[node];
    return !is.defined(o);
  });

  return o;
};

export default safeGet;
