import Vue from 'vue';
import dialog from '@/components/dialog/dialog';
import TextDialog from '@/components/dialog/TextDialog.vue';

const messageDialog = (title, content, closeText, type) =>
  dialog({
    props: {
      title,
      content,
      cancelText: '',
      confirmText: closeText,
      type,
      confirm: false,
    },
    component: Vue.extend(TextDialog),
  });

export default messageDialog;
